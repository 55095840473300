import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const Certification = ({ year, name, institution, link, badge }) => {
  return (
    <div className="education text-start m-0 my-3 p-0">
      <div className="row">
        <hr />
        <div className="col-3 p-0 text-center pe-2 pe-sm-3">
          <GatsbyImage image={getImage(badge)} alt={`${name} certification`} />
        </div>

        <div className="col">
          <div className="row">
            <p className="display-8 mb-3 d-inline-block pe-sm-3">
              <span className="me-1">
                <a
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  className="display-8"
                >
                  <em>
                    <b>{name}</b>
                  </em>
                </a>
              </span>
              {", "}
              <em className="display-9">{year}</em>
            </p>
          </div>

          <div className="row">
            <p className="display-9">{institution}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certification;
