import React from "react";
import Layout from "../components/Layout";
import Experience from "../components/Experience";
import Education from "../components/Education";
import Certification from "../components/Certification";
import { graphql } from "gatsby";

const about = ({ data }) => {
  return (
    <Layout active={"about"}>
      <title>About Me | DoingCloudStuff</title>

      <h1 className="display-4 mt-5 mb-1 pt-5 pb-3">About me</h1>

      <section class="display-7">
        <p>
          If you're reading this, then I can only assume you're looking to learn
          a little bit more about me.
        </p>
        <p>
          I am <b>Vincent Chan</b> and I am a cloud developer and architect (I
          was trained as a computational physicist) who is fasinated with all
          things cloud-related.
        </p>
        <p>
          Particularly, I am amazed with how things that I would've thought to
          be expensive and technically difficult to implement, are actually
          pretty doable and inexpensive, hence a blog site devoted to it.
        </p>
        <p>
          In case you want to know more about me, please scroll down to read
          more.
        </p>
      </section>

      <section className="display-7 m-0 p-0 pt-5 my-5 text-center">
        <h2 className="display-5 m-0 p-0">Experience</h2>
        <Experience
          title="Cloud Architect"
          company="Market (Trust Software Inc.)"
          dates="10/2021 – 01/2023"
          description="Update CI/CD pipeline, providing developers with greater autonomy; fix and manage the kuberenetes cluster (on EKS); lead the migration of resources between AWS accounts with minimal downtime; and plan and execute multi-region deployments and disaster recovery"
          techstack={[
            "AWS IAM",
            "SecretsManager",
            "Route53",
            "CloudFront",
            "Lambda",
            "EventBridge",
            "SQS",
            "S3",
            "DynamoDB",
            "RDS (PostgreSQL)",
            "Redshift",
            "Terraform",
            "Kubernetes (on EKS)",
            "Helm",
          ]}
          logo={data.market}
          aws={false}
        />
        <Experience
          title="Applications Developer"
          company="PennyMac Loan Services, LLC"
          dates="12/2020 – 10/2021"
          description="Architecting and building out a new event-driven processing pipeline on AWS for processing 
          loan applications coming from disparate sources."
          techstack={[
            "Lambda",
            "Stepfunction",
            "EventBridge",
            "SQS",
            "Kinesis Data Firestore",
            "S3",
            "DynamoDB",
            "API Gateway",
            "AppSync",
            "Cloudformation & SAM",
            "AWS CDK",
          ]}
          logo={data.pennymac}
        />
        <Experience
          title="Subject Matter Expert"
          company="AWS Certification and Training"
          dates="week of 10/19/2020"
          description="For the week of Oct. 19th, 2020, I participated in AWS' CLF Remote Item Development Workshop.
          As part of a group with other SME (Subject Matter Experts), I reviewed the questions and answers in the AWS Certified Cloud Practitioner exam for accuracy and clarity, and created new questions to be used in future AWS Certified Cloud Practitioner exams."
          note="AWS was not my employer. This was closer to a volunteer position, albeit one that you have to apply for."
          logo={data.aws}
        />
        <Experience
          title="Data Engineer"
          company="Content.ad"
          dates="04/2020 – 09/2020"
          description="Worked directly with the CTO to design and build a new streaming data pipeline to replace the buggy, legacy pipeline with, resulting in lowered processing
          time and improved responsiveness. The pipeline was stable and processing at ∼2TB a day when I left."
          techstack={[
            "Lambda",
            "Kinesis Data Stream",
            "Kinesis Data Analytics",
            "SQS",
            "RDS",
            "ElastiCache",
            "DynamoDB",
            "S3",
            "CloudWatch",
            "Cloudformation & SAM",
          ]}
          logo={data.contentad}
        />
        <Experience
          title="Data Engineer Fellow"
          company="Insight Data Science"
          dates="09/2019 – 04/2020"
          description="Processed ∼200GB of financial market data from AWS S3 using Spark in an AWS EC2
          cluster to look for certain types of opportunities (triangular arbitrages)."
          techstack={["EC2", "IAM", "VPC & Subnets", "S3", "RDS"]}
          logo={data.insight}
        />
      </section>

      <section className="display-7 m-0 p-0 pt-5 mt-5 text-center">
        <h2 className="display-5 m-0 p-0">Education</h2>
        <Education
          degree="Science (Computational Mathematics), M.S."
          university="Okinawa Institute of Science and Technology Graduate University"
          year="2018"
          type="thesis title"
          name="A novel numerical solver: the Lagrangian solver"
          logo={data.oist}
        />
        <Education
          degree="Physics, M.S."
          university="California State University, Long Beach"
          year="2013"
          type="thesis title"
          name="Numerical simulation of relativistic fluids"
          logo={data.csulb}
        />
        <Education
          degree="Chemical Engineering, B.S."
          university="University of California, Irvine"
          year="2010"
          type="UCI rocket group"
          name="Designed and built solid motor rocket that reached over 12,000 ft in 2010."
          logo={data.uci}
        />
      </section>

      <section className="display-7 m-0 p-0 py-5 my-5 text-center">
        <h2 className="display-5 m-0 mb-5 p-0">Certifications</h2>

        <Certification
          year="2022"
          name="AWS Certified Solutions Architect – Professional"
          institution="Amazon Web Services"
          link="https://www.credly.com/badges/10d5a09a-4a47-4d17-8451-4861f3bb1720/public_url"
          badge={data.sapro}
        />
        <Certification
          year="2022"
          name="AWS Certified Database – Specialty"
          institution="Amazon Web Services"
          link="https://www.credly.com/badges/8491dacc-12d1-4635-a49c-a6fe507ba022"
          badge={data.db}
        />
        <Certification
          year="2021"
          name="HashiCorp Terraform Associate"
          institution="HashiCorp"
          link="https://www.credly.com/badges/f48967fe-72ec-4c2b-8504-c360e91e242d"
          badge={data.terraform}
        />
        <Certification
          year="2021"
          name="Certified Kubernetes Administrator"
          institution="The Linux Foundation"
          link="https://www.credly.com/badges/851abee2-2223-4538-a92b-3972be0a1762"
          badge={data.cka}
        />
        <Certification
          year="2020"
          name="AWS Certified Solutions Architect – Associate"
          institution="Amazon Web Services"
          link="https://www.credly.com/badges/576e62a6-b2db-4b2c-b225-168d0990d875"
          badge={data.sa}
        />
        <Certification
          year="2020"
          name="AWS Certified Developer – Associate"
          institution="Amazon Web Services"
          link="https://www.credly.com/badges/59cdb38a-304b-4a9e-83d0-28a707eaf0fd"
          badge={data.dev}
        />
        <Certification
          year="2020"
          name="AWS Certified SysOps Administrator – Associate"
          institution="Amazon Web Services"
          link="https://www.credly.com/badges/9b82a16e-6435-4721-badd-c1db8232a33f"
          badge={data.sysop}
        />
      </section>

      <section className="my-5 py-5"></section>
    </Layout>
  );
};

export const query = graphql`
  {
    db: imageSharp(original: { src: { regex: "/aws-db-192-.*/" } }) {
      gatsbyImageData(width: 130)
    }
    sa: imageSharp(original: { src: { regex: "/sa-192-.*/" } }) {
      gatsbyImageData(width: 130)
    }
    sapro: imageSharp(original: { src: { regex: "/sa-pro-192-.*/" } }) {
      gatsbyImageData(width: 130)
    }
    dev: imageSharp(original: { src: { regex: "/dev-192-.*/" } }) {
      gatsbyImageData(width: 130)
    }
    sysop: imageSharp(original: { src: { regex: "/sys-op-192-.*/" } }) {
      gatsbyImageData(width: 130)
    }
    cka: imageSharp(original: { src: { regex: "/cka-192-.*/" } }) {
      gatsbyImageData(width: 135)
    }
    oist: imageSharp(original: { src: { regex: "/oist-logo-.*/" } }) {
      gatsbyImageData(width: 120)
    }
    csulb: imageSharp(original: { src: { regex: "/csulb-logo-.*/" } }) {
      gatsbyImageData(width: 130)
    }
    uci: imageSharp(original: { src: { regex: "/uci-logo-.*/" } }) {
      gatsbyImageData(width: 120)
    }
    pennymac: imageSharp(original: { src: { regex: "/pennymac-logo-.*/" } }) {
      gatsbyImageData(width: 180)
    }
    contentad: imageSharp(original: { src: { regex: "/contentad-logo-.*/" } }) {
      gatsbyImageData(width: 70)
    }
    insight: imageSharp(original: { src: { regex: "/insight-logo-.*/" } }) {
      gatsbyImageData(width: 90)
    }
    aws: imageSharp(original: { src: { regex: "/aws-logo-.*/" } }) {
      gatsbyImageData(width: 70)
    }
    market: imageSharp(original: { src: { regex: "/market.*/" } }) {
      gatsbyImageData(width: 120)
    }
    terraform: imageSharp(
      original: { src: { regex: "/terraform-badge-.*/" } }
    ) {
      gatsbyImageData(width: 130)
    }
  }
`;

export default about;
