import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const Experience = ({
  title,
  company,
  dates,
  description,
  logo,
  techstack = null,
  note = null,
  aws = true,
}) => {
  const reducer = (acc, val) => `${acc}, ${val}`;
  return (
    <div className="experience text-start m-0 my-5 p-0">
      <div className="row">
        <hr />
        <div className="col-3 p-0 text-center pe-2 pe-sm-3">
          <GatsbyImage image={getImage(logo)} alt={`${company} logo`} />
        </div>

        <div className="col">
          <div className="row">
            <p className="display-8 mb-0">
              <b>{title}</b>
            </p>
          </div>
          <div className="row">
            <p className="display-8 mb-2">
              <em className="display-9">{`${company}`}</em>
              {","}
              <span className="display-9 ps-1">{dates}</span>
            </p>
          </div>

          {techstack !== null && (
            <div className="row mb-4">
              <p className="display-9">
                <b>{aws ? "AWS tech stack:" : "Tech stack:"} </b>
                <em>{techstack.reduce(reducer)}</em>
              </p>
            </div>
          )}

          {note !== null && (
            <div className="row mb-4">
              <p className="display-9">
                <b>Note: </b>
                {note}
              </p>
            </div>
          )}

          <div className="row">
            <p className="display-9">
              <b>Description: </b>
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
