import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const Education = ({ degree, university, year, type, name, logo }) => {
  return (
    <div className="education text-start m-0 my-5 p-0">
      <div className="row">
        <hr />
        <div className="col-3 p-0 text-center pe-2 pe-sm-3">
          <GatsbyImage image={getImage(logo)} alt={`${university} logo`} />
        </div>
        <div className="col">
          <div className="row">
            <p className="display-8 mb-3">
              <span className="me-1">
                <b>{degree}</b>,
              </span>
              <em className="display-9">{university}, </em>
              <span className="display-9">{year}</span>
            </p>
          </div>
          <div className="row">
            <p className="display-9">
              <b>{type}:</b> <em>{name}</em>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
